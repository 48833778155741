<template>
  <div>
    <b-modal ref="warningDialog" title="Objects Not Added" size="lg" scrollable
      header-bg-variant="warning" header-text-variant="dark"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      content-class="shadow" @ok="handleWarningOK" ok-only>
      <span v-html="warningMsg"></span>
    </b-modal>
    <b-modal ref="modalRef" title="Add Objects to Study" size="xl" scrollable
      header-bg-variant="secondary" header-text-variant="light"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      no-close-on-esc no-close-on-backdrop hide-header-close
      content-class="shadow" ok-title="Cancel" ok-only ok-variant="secondary" :ok-disabled="isUploading">
      <b-badge class="d-none d-sm-block overflow-hidden" variant="info" show size="sm">
        <b-icon v-if="entry.stat" scale="1.0" icon="patch-exclamation-fill" class="text-danger mr-1"></b-icon>
        <b-icon v-if="entry.read" scale="1.0" icon="check-circle-fill"></b-icon>
        &nbsp;
        {{ title }}
      </b-badge>
      <b-form>
        <b-row class="mt-2">
          <b-col>
            <b-form-group label="" label-for="dir_mode" label-cols="0">
              <b-form-radio-group id="dir_mode" v-model="selectMode" @change="objectFiles=[]">
                <b-form-radio value="FILE">Select File(s)</b-form-radio>
                <b-form-radio value="DIR">Select Directory</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="selectMode=='FILE'" class="mt-1">
          <b-col>
            <b-form-group label="" label-for="object_file" label-cols="0">
              <b-form-file
                browse-text="Select File(s)"
                :file-name-formatter="fileNameFormatter"
                class="ml-1"
                v-model="objectFiles"
                id="object_file"
                :disabled="isUploading"
                :state="Boolean(objectFiles.length>0)"
                :accept="acceptTypes"
                placeholder="Choose file(s) or drop here..."
                multiple
                drop-placeholder="Drop file(s) here..."/>
              <b-form-text id="input-live-help">File size limited to {{uploadLimitMb}} MB.&nbsp;
                <b-badge v-if="!objectFileSizesOkay" variant="warning">One or more files exceed this limit.</b-badge>
                </b-form-text>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="selectMode=='DIR'" class="mt-1">
          <b-col>
            <b-form-group label="" label-for="object_dir" label-cols="0">
              <b-form-file
                browse-text="Select Directory"
                :file-name-formatter="fileNameFormatter"
                class="ml-1"
                v-model="objectFiles"
                id="object_dir"
                :disabled="isUploading"
                :state="Boolean(objectFiles.length>0)"
                placeholder="Choose folder..."
                no-traverse
                multiple
                directory />
              <b-form-text id="input-live-help">File size limited to {{uploadLimitMb}} MB.&nbsp;
                <b-badge v-if="!objectFileSizesOkay" variant="warning">One or more files exceed this limit.</b-badge>
              </b-form-text>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="6">
            <b-progress v-if="isUploading" :max="objectFiles.length" show-value>
              <b-progress-bar variant="danger" :value="objectFilesI-numOK"></b-progress-bar>
              <b-progress-bar variant="success" :value="numOK"></b-progress-bar>
            </b-progress>
            <b-progress v-if="isUploading" class="mt-1" :max="objectFileSize" show-progress>
              <b-progress-bar variant="info" :value="objectFileUploaded"></b-progress-bar>
            </b-progress>
          </b-col>
          <b-col cols="6">
            <div class="float-right">
              <b-button @click="uploadObjects" :disabled="isUploading || (objectFiles.length == 0) || !objectFileSizesOkay" variant="primary">
                <b-spinner v-if="isUploading" small></b-spinner><span v-if="isUploading">&nbsp;Uploading {{objectFilesI + 1}} of {{objectFiles.length}}...</span>
                <span v-if="!isUploading">Add to Study</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import webServices from '../common/webServices'

export default {
  name: 'addToStudy',
  components: {
  },
  data() {
    return {
      acceptTypes: ".dcm, .gif, .jpg, .jpeg, .png", // +TODO+ support .avi, .mp4
      isUploading: false,
      numOK: 0,
      objectFilesI: 0,
      objectFiles: [],
      objectFileSize: 0,
      objectFileUploaded: 0,
      objectItems: [],
      selectMode: 'DIR',
      studyUid: '',
      warningMsg: ''
    }
  },
  computed: {
    entry() {
      const entry = this.$store.getters.worklistEntryForStudy(this.studyUid)
      if (entry != null) {
        return entry
      }
      else {
        return webServices.getEmptyWorklistEntry()
      }
    },
    title() {
      return webServices.getTitleForEntry(this.entry)
    },
    uploadLimitMb() {
      return this.$store.state.uploadLimitMb;
    },
    objectFileSizesOkay() {
      var okay = true
      for (var i=0; i<this.objectFiles.length; i++) {
        if (this.objectFiles[i].size > this.uploadLimitMb*1024*1024) {
          okay = false
          return
        }
      }
      return okay
    }
  },
  methods: {
    show(studyUid) {
      this.studyUid = studyUid
      this.numOK = 0
      this.objectFilesI = 0
      this.objectFiles = []
      this.warningMsg = ''
      this.isUploading = false
      this.$refs['modalRef'].show()
    },
    hide() {
      this.$refs['modalRef'].hide()
    },
    fileNameFormatter(files/*, filesTraversed, names*/) {
      var fileNames = "Selected "+files.length+" file(s)"
      if (this.selectMode == 'DIR') {
        const path = files[0].$path
        var lastI = path.lastIndexOf("/")
        if (lastI == -1) { lastI = path.lastIndexOf("\\");  }
        const dirName = path.substring(0,lastI)
        fileNames +=" from "+dirName
      }
      return fileNames
    },
    uploadOneObject() {
      this.isUploading = true;
      const objectFile = this.objectFiles[this.objectFilesI]
      var objectFileName = objectFile.name
      this.objectFileSize = objectFile.size
      this.$log.debug("objectFileSize="+this.objectFileSize)
      this.objectFileUploaded = 0
      var reader = new FileReader();
      var thisForReader = this
      reader.onerror = function(/*file*/) {
        thisForReader.$log.error("Error reading file="+objectFileName)
        reader.abort();
        thisForReader.warningMsg = "Error reading file="+objectFileName
        thisForReader.$refs.warningDialog.show()
        thisForReader.isUploading = false;
      };

      reader.onload = function(/*file*/) {
        thisForReader.$log.debug("Read file="+objectFileName)
        webServices.createStudyObject(thisForReader.entry, new Blob([reader.result]), objectFileName, (progressEvent) => {
          thisForReader.objectFileUploaded = Math.min(progressEvent.loaded, thisForReader.objectFileSize)
        })
        .then(response => {
          thisForReader.$log.debug(`Uploaded file=[${objectFileName}] response=[${response}]`)
          thisForReader.numOK++
        })
        .catch(err => {
          thisForReader.$log.error("Error uploading object: "+err.message)
          thisForReader.warningMsg += "File:&nbsp;"+objectFileName+"<br/>"
        })
        .finally(() => {
          if (thisForReader.objectFiles.includes(objectFile)) {
            thisForReader.objectFilesI++
            if (thisForReader.objectFilesI < thisForReader.objectFiles.length) {
              thisForReader.uploadOneObject()
            }
            else {
              // Done!
              //
              thisForReader.isUploading = false
              thisForReader.hide()
              if (thisForReader.numOK > 0) {
                thisForReader.$bvToast.toast("Added "+thisForReader.numOK+" object(s) to "+thisForReader.title, {
                  autoHideDelay: 5000,
                  solid: true,
                  title: 'INSPIRE PACS',
                  variant: 'info'
                })
                webServices.readWorklist()
              }
              if (thisForReader.objectFiles.length != thisForReader.numOK) {
                thisForReader.$refs.warningDialog.show()
              }
            }
          }
          else {
            // Response is from a prior upload - ignore
            //
            thisForReader.$log.debug("Ignoring response for previous upload request, object="+objectFileName)
          }
        })
      }
      reader.readAsArrayBuffer(objectFile)
    },
    uploadObjects() {
      // Start uploading the objects
      //
      this.objectFilesI = 0
      this.numOK = 0
      this.warningMsg = ''
      this.uploadOneObject()
    },
    handleWarningOK() {
      this.warningMsg = ''
    }
  }
};
</script>
<style scoped>
</style>