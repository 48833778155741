<template>
  <div>
    <b-modal ref="modalRef" title="Route Study" size="lg" scrollable
      header-bg-variant="secondary" header-text-variant="light"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      no-close-on-esc no-close-on-backdrop hide-header-close
      content-class="shadow" ok-title="Cancel" ok-only ok-variant="secondary" :ok-disabled="isRouting">
      <b-badge class="d-none d-sm-block overflow-hidden" variant="info" show size="sm">
        <b-icon v-if="entry.stat" scale="1.0" icon="patch-exclamation-fill" class="text-danger mr-1"></b-icon>
        <b-icon v-if="entry.read" scale="1.0" icon="check-circle-fill"></b-icon>
        &nbsp;
        {{ title }}
      </b-badge>
      <b-form>
        <b-row class="mt-2">
          <b-col>
            <b-form-group label="Destination" label-for="routeToAet" label-cols="3">
              <b-form-select id="routeToAet" v-model="routeToAet">
                <b-form-select-option value="">Select...</b-form-select-option>
                <b-form-select-option v-for="dest in destinations" v-bind:key="dest.aet" :value="dest.aet">{{dest.label}}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col>
            <div class="float-right">
              <b-button @click="routeStudy" :disabled="isRouting || (routeToAet == '')" variant="primary">
                Route Study
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import webServices from '../common/webServices'

export default {
  name: 'routeStudy',
  components: {
  },
  data() {
    return {
      destinations: [],
      isRouting: false,
      routeToAet: '',
      studyUid: ''
    }
  },
  computed: {
    entry() {
      const entry = this.$store.getters.worklistEntryForStudy(this.studyUid)
      if (entry != null) {
        return entry
      }
      else {
        return webServices.getEmptyWorklistEntry()
      }
    },
    title() {
      return webServices.getTitleForEntry(this.entry)
    }
  },
  methods: {
    show(studyUid) {
      this.studyUid = studyUid
      let priorRouteToAet = this.routeToAet
      this.routeToAet = ''
      this.destinations = []
      this.$refs['modalRef'].show()
      webServices.readGroupRouteAets('/'+this.entry.group)
      .then(response => {
        if ((response != null) && (Array.isArray(response))) {
          this.destinations = response
          for (var d = 0; d < this.destinations.length; d++) {
            if (this.destinations[d].aet == priorRouteToAet) {
              this.routeToAet = priorRouteToAet
              break
            }
          }
        }
      })
      .catch(err => {
        this.$log.error("Error getting group users: "+err.message)
      })
    },
    hide() {
      this.$refs['modalRef'].hide()
    },
    routeStudy() {
      webServices.routeStudy(this.entry, this.routeToAet)
      .then(() => {
        let msg =  "[" + webServices.getTitleForEntry(this.entry) + "] Routing study to " + this.routeToAet + "."
        this.$bvToast.toast(msg, {
            autoHideDelay: 5000,
            solid: true,
            title: 'INSPIRE PACS',
            variant: 'info',
        })
      })
      .catch(err => {
        this.$log.error("Error routing study: "+err.message)
        let msg =  "[" + webServices.getTitleForEntry(this.entry) + "] Error routing study."
        this.$bvToast.toast(msg, {
            autoHideDelay: 5000,
            solid: true,
            title: 'INSPIRE PACS',
            variant: 'danger',
        })
      })
      .finally(() => {
        this.hide()
      })
    }
  }
};
</script>
<style scoped>
</style>