// Class define a set of properties used for worklist filters for queries.
//
class WorklistFilter {
    constructor() {
        this.default = false
        this.accessionNumber = ''
        this.groups = [],
        this.modalities = []
        this.patientId = ''
        this.patientName = ''
        this.referringPhysicianName = ''
        this.reportedBy = ''
        this.studyDateFilter = 'today'
        this.studyDateFrom = ''
        this.studyDateTo = ''
        this.studyId = ''
        this.worklistName = ''
    }

    // Copy property values from a settings object that may not have the most 
    // current set of properties.
    //
    copy(worklistFilter) {
        const propNames = Object.keys(this)
        for(var i=0; i < propNames.length; i++) {
            const propName = propNames[i]
            try {
                if (typeof(worklistFilter[propName]) === 'undefined') {
                    // ignore
                }
                else if (typeof(worklistFilter[propName]) === 'object') {
                    Object.assign(this[propName], worklistFilter[propName])
                }
                else {
                    this[propName] = worklistFilter[propName]
                }
            }
            catch(e) {
                // ignore missing property
            }
        }
    }
}

module.exports = WorklistFilter;