<template>
  <div ref="topDiv">
    <AddToStudy ref="addToStudy"/>
    <AssignStudy ref="assignStudy"/>
    <PatientInfoEditor ref="patientInfoEditor"/>
    <RouteStudy ref="routeStudy"/>
    <a ref="altViewerLauncher" target="_blank"></a>
    <b-table id="wlTable" ref="wlTable" outlined class="wlTable small"
        :sticky-header="tableHeight"
        @sort-changed="handleSortChanged"
        @row-clicked="rowClicked"
        @row-dblclicked="rowDblClicked"
        :items="filtered"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-compare="sortCompare"
        fixed
        responsive
        primary-key="study_uid">
      <template #table-colgroup="scope">
        <col v-for="field in scope.fields" :key="field.key" :style="'width:'+worklistColumnWidths[field.key]+'px;'">
      </template>
      <template v-slot:head()="data">
        <div v-html="data.field.label"></div>
        <div>
          <template v-if="data.field.key=='checkbox'">
            <b-checkbox @change="actionCheckedAll"/>
          </template>
          <template v-if="data.field.key=='actions'">
            <b-button size="sm" title="Reset Search Terms" variant="secondary" @click="clearFilters">
              <span class="material-icons">&#xf053;</span> 
            </b-button>
          </template>
          <input v-if="data.field.search" v-model="filters[data.field.key]" :size="worklistColumnWidths[data.field.key] / 10"/>
          <span v-if="(data.field.key!='checkbox') && !data.field.search && (data.field.options==undefined)">&nbsp;</span>
          <template v-if="(data.field.options!==undefined) && (data.field.options.length>0)">
            <b-form-select v-model="filters[data.field.key]" size="sm">
              <b-form-select-option value="">&nbsp;</b-form-select-option>
              <b-form-select-option v-for="option in data.field.options" v-bind:key="option" :value="option">{{option}}
              </b-form-select-option>
            </b-form-select>
          </template>
          <template v-if="data.field.key=='group'">
            <b-form-select v-model="filters[data.field.key]" size="sm" :style="'width: '+(worklistColumnWidths[data.field.key]-30)+'px;'">
              <b-form-select-option value="">&nbsp;</b-form-select-option>
              <b-form-select-option v-for="option in groups" v-bind:key="option" :value="option">{{option}}
              </b-form-select-option>
            </b-form-select>
          </template>
          <template v-if="data.field.key=='modality'">
            <b-form-select v-model="filters[data.field.key]" size="sm" :style="'width: '+(worklistColumnWidths[data.field.key]-30)+'px;'">
              <b-form-select-option value="">&nbsp;</b-form-select-option>
              <b-form-select-option v-for="option in modalities" v-bind:key="option" :value="option">{{option}}
              </b-form-select-option>
            </b-form-select>
          </template>
        </div>
      </template>
      <template #cell(checkbox)="data">
        <b-checkbox v-if="showCheckboxes" v-model="checkedStudies" :value="data.item.group+'|'+data.item.study_uid" @change="actionCheckedStudy" :disabled="lockStudy(data.item)"/>
      </template>
      <template #cell(actions)="data">
        <div class="ellipsis">
          <b-button-group aria-label="Actions" :size="buttonSize">
            <b-button v-if="osirixEnabled" class="pl-0 pr-1" variant="secondary" title="Launch OsiriX to View Study" @click="actionOsiriX(data.item.study_uid)" :disabled="!data.item.can_view_images|| lockStudy(data.item)">
              <b-icon icon="box-arrow-up-right" scale="0.5"></b-icon>
              <span class="viewerlabel">O</span>
            </b-button>
            <b-button v-if="radiantEnabled" class="pl-0 pr-1" variant="secondary" title="Launch RadiAnt to View Study" @click="actionRadiAnt(data.item.study_uid)" :disabled="!data.item.can_view_images|| lockStudy(data.item)">
              <b-icon icon="box-arrow-up-right" scale="0.5"></b-icon>
              <span class="viewerlabel">R</span>
            </b-button>
            <b-button v-if="slicerEnabled" class="pl-0 pr-1" variant="secondary" title="Launch 3D Slicer to View Study" @click="actionSlicer(data.item.study_uid)" :disabled="!data.item.can_view_images|| lockStudy(data.item)">
              <b-icon icon="box-arrow-up-right" scale="0.5"></b-icon>
              <span class="viewerlabel">S</span>
            </b-button>
            <b-button v-if="weasisEnabled" class="pl-0 pr-1" variant="secondary" title="Launch Weasis to View Study" @click="actionWeasis(data.item.study_uid)" :disabled="!data.item.can_view_images|| lockStudy(data.item)">
              <b-icon icon="box-arrow-up-right" scale="0.5"></b-icon>
              <span class="viewerlabel">W</span>
            </b-button>
            <b-button v-if="viewerLiteEnabled" variant="secondary" title="View Study (Lite Viewer)" @click="actionViewer(data.item.study_uid, false)" :disabled="!data.item.can_view_images || lockStudy(data.item)">
              <b-icon icon="phone"></b-icon>
            </b-button>
            <b-button v-if="viewerFullEnabled" variant="secondary" title="View Study (Full Viewer)" @click="actionViewer(data.item.study_uid, true)" :disabled="!data.item.can_view_images || lockStudy(data.item)"><b-icon icon="display"></b-icon></b-button>
            <b-button v-if="exportStudyEnabled" variant="secondary" title="Export Study (all DICOM objects in zip file)" @click="actionDownloadStudy(data.item.study_uid)" :disabled="!data.item.can_download_study || lockStudy(data.item)"><b-icon icon="download"></b-icon></b-button>
            <b-button v-if="routeStudyEnabled" variant="secondary" title="Route Study" @click="actionRouteStudy(data.item.study_uid)" :disabled="!data.item.can_route_study">
              <b-icon icon="box-arrow-up"></b-icon>
            </b-button>
            <b-button v-if="addObjectsEnabled" variant="secondary" title="Add Objects to Study" @click="actionAddToStudy(data.item.study_uid)" :disabled="!data.item.can_upload_study || lockStudy(data.item)"><b-icon icon="paperclip"></b-icon></b-button>
            <b-button v-if="markStatEnabled" variant="secondary" title="Mark/Unmark Study as Emergency" @click="actionMarkStat(data.item.study_uid)" :disabled="!data.item.can_mark_stat">
              <b-icon icon="exclamation-circle"></b-icon>
            </b-button>
            <b-button v-if="markReadEnabled" variant="secondary" title="Mark/Unmark Study as Read" @click="actionMarkRead(data.item.study_uid)" :disabled="!canMarkRead(data.item) || lockStudy(data.item)">
              <b-icon icon="check-circle"></b-icon>
            </b-button>
            <b-button v-if="assignStudyEnabled" variant="secondary" title="Assign Study" @click="actionAssignStudy(data.item.study_uid)" :disabled="!data.item.can_assign_studies || lockStudy(data.item)"><b-icon icon="person-check"></b-icon></b-button>
            <b-button v-if="unlockStudyEnabled" variant="secondary" title="Unlock Study" @click="actionUnlockStudy(data.item.study_uid)" :disabled="!data.item.can_assign_studies || (data.item.lock == '') || (data.item.lock == userId)">
              <b-icon icon="unlock"></b-icon>
            </b-button>
            <b-button v-if="patientHistoryEnabled" :variant="(data.item.ph) ? 'info' : 'secondary'" title="Patient History" @click="actionPatientHistory(data.item.study_uid)" :disabled="!data.item.can_view_patient_history || lockStudy(data.item)">
              <b-icon icon="folder2-open"></b-icon>
            </b-button>
            <b-button v-if="editPatientEnabled" variant="secondary" title="Edit Patient Demographics" @click="actionEditPatientInfo(data.item.study_uid)" :disabled="!data.item.can_edit_pi || lockStudy(data.item)"><b-icon icon="pencil-square"></b-icon></b-button>
            <b-button v-if="viewReportEnabled" :variant="(data.item.report_status!='---') ? 'info' : 'secondary'" title="View Report" @click="actionReport(data.item.study_uid)" :disabled="!data.item.can_view_reports || lockStudy(data.item) || actionReportDisabled(data.item.study_uid)"><b-icon icon="journal-medical"></b-icon></b-button>
          </b-button-group>
        </div>
      </template>
      <template #cell(actions_dropdown)="data">
        <b-dropdown class="bg-dark ml-1" aria-label="Actions" :size="buttonSize">
          <template #button-content>
            <b-icon icon="menu-button-fill"></b-icon>
          </template>
          <b-dropdown-item-btn v-if="data.item.can_view_images" variant="dark" @click="actionViewer(data.item.study_uid, false)" :disabled="lockStudy(data.item)">
            <b-icon icon="phone"></b-icon> View Study (Lite Viewer)
          </b-dropdown-item-btn>
          <b-dropdown-item-btn v-if="data.item.can_view_images" variant="dark" @click="actionViewer(data.item.study_uid, true)" :disabled="lockStudy(data.item)">
            <b-icon icon="display"></b-icon> View Study (Full Viewer)
          </b-dropdown-item-btn>
          <b-dropdown-item-btn v-if="osirixEnabled && data.item.can_view_images" variant="dark" @click="actionOsiriX(data.item.study_uid)" :disabled="lockStudy(data.item)">
            <b-icon icon="box-arrow-up-right"></b-icon> View Study (OsiriX)
          </b-dropdown-item-btn>
          <b-dropdown-item-btn v-if="radiantEnabled && data.item.can_view_images" variant="dark" @click="actionRadiAnt(data.item.study_uid)" :disabled="lockStudy(data.item)">
            <b-icon icon="box-arrow-up-right"></b-icon> View Study (RadiAnt)
          </b-dropdown-item-btn>
          <b-dropdown-item-btn v-if="slicerEnabled && data.item.can_view_images" variant="dark" @click="actionSlicer(data.item.study_uid)" :disabled="lockStudy(data.item)">
            <b-icon icon="box-arrow-up-right"></b-icon> View Study (Slicer)
          </b-dropdown-item-btn>
          <b-dropdown-item-btn v-if="weasisEnabled && data.item.can_view_images" variant="dark" @click="actionWeasis(data.item.study_uid)" :disabled="lockStudy(data.item)">
            <b-icon icon="box-arrow-up-right"></b-icon> View Study (Weasis)
          </b-dropdown-item-btn>
          <b-dropdown-item-btn v-if="data.item.can_download_study" variant="dark" @click="actionDownloadStudy(data.item.study_uid)" :disabled="lockStudy(data.item)">
            <b-icon icon="download"></b-icon> Export Study
          </b-dropdown-item-btn>
          <b-dropdown-item-btn v-if="data.item.can_route_study" variant="dark" @click="actionRouteStudy(data.item.study_uid)" :disabled="!data.item.can_route_study">
            <b-icon icon="box-arrow-up"></b-icon> Route Study
          </b-dropdown-item-btn>
          <b-dropdown-item-btn v-if="data.item.can_upload_study" variant="dark" @click="actionAddToStudy(data.item.study_uid)" :disabled="lockStudy(data.item)">
            <b-icon icon="paperclip"></b-icon> Add Objects to Study
          </b-dropdown-item-btn>
          <b-dropdown-item-btn v-if="data.item.can_mark_stat" variant="dark" @click="actionMarkStat(data.item.study_uid)">
            <b-icon icon="exclamation-circle"></b-icon> Mark/Unmark Study as Emergency
          </b-dropdown-item-btn>
          <b-dropdown-item-btn v-if="canMarkRead(data.item)" variant="dark" @click="actionMarkRead(data.item.study_uid)" :disabled="lockStudy(data.item)">
            <b-icon icon="check-circle"></b-icon> Mark/Unmark Study as Read
          </b-dropdown-item-btn>
          <b-dropdown-item-btn v-if="data.item.can_assign_studies" variant="dark" @click="actionAssignStudy(data.item.study_uid)" :disabled="lockStudy(data.item)">
            <b-icon icon="person-check"></b-icon> Assign Study
          </b-dropdown-item-btn>
          <b-dropdown-item-btn v-if="data.item.can_assign_studies" variant="dark" @click="actionUnlockStudy(data.item.study_uid)" :disabled="(data.item.lock == '') || (data.item.lock == userId)">
            <b-icon icon="unlock"></b-icon> Unlock Study
          </b-dropdown-item-btn>
          <b-dropdown-item-btn v-if="data.item.can_view_patient_history" :variant="(data.item.ph) ? 'info' : 'dark'" @click="actionPatientHistory(data.item.study_uid)" :disabled="lockStudy(data.item)">
            <b-icon icon="folder2-open"></b-icon> Patient History
          </b-dropdown-item-btn>
          <b-dropdown-item-btn v-if="data.item.can_edit_pi" variant="dark" @click="actionEditPatientInfo(data.item.study_uid)" :disabled="lockStudy(data.item)">
            <b-icon icon="pencil-square"></b-icon> Edit Patient Demographics
          </b-dropdown-item-btn>
          <b-dropdown-item-btn v-if="data.item.can_view_reports" :variant="(data.item.report_status!='---') ? 'info' : 'dark'" @click="actionReport(data.item.study_uid)" :disabled="lockStudy(data.item) || actionReportDisabled(data.item.study_uid)">
            <b-icon icon="journal-medical"></b-icon> View Report
          </b-dropdown-item-btn>
        </b-dropdown>
      </template>
      <template #cell(diagnostic)="data">
        <b-icon v-if="!data.item.stat" scale="1.25" icon="dash" :class="'text-'+data.item._rowVariant"></b-icon>
        <b-icon v-if="data.item.stat" scale="1.25" icon="patch-exclamation-fill" class="text-danger"></b-icon>
        <b-icon v-if="!data.item.read" scale="1.25" icon="circle" :class="'ml-1 text-'+data.item._rowVariant"></b-icon>
        <b-icon v-if="data.item.read" scale="1.25" icon="check-circle-fill" :class="'ml-1 text-'+data.item._rowVariant"></b-icon>
        &nbsp;{{diagnostic(data.item)}}
      </template>
      <template #cell(lock)="data">
        <b-icon v-if="data.item.lock==''" scale="1.25" icon="unlock"></b-icon>
        <b-icon v-if="data.item.lock!=''" 
         scale="1.25" icon="lock-fill" 
         :title="data.item.lufn"
         :class="(data.item.lock==userId) ? 'text-info' : 'text-black'">
        </b-icon>
      </template>
      <template #cell(dob)="data">
        <div class="noellipsis">{{(data.item.dob==null) ? '---' : new Date(data.item.dob).toLocaleDateString(locale)}}</div>
      </template>
      <template #cell(report_date_time)="data">
        <div class="noellipsis">{{(data.item.report_date_time==null) ? '---' : new Date(data.item.report_date_time).toLocaleString(locale)}}</div>
      </template>
      <template #cell(rcvd_date_time)="data">
        <div class="noellipsis">{{(data.item.rcvd_date_time==null) ? '---' : new Date(data.item.rcvd_date_time).toLocaleString(locale)}}</div>
      </template>
      <template #cell(study_date_time)="data">
        <div class="noellipsis">{{(data.item.study_date_time==null) ? '---' : new Date(data.item.study_date_time).toLocaleString(locale)}}</div>
      </template>
      <template #cell(acc_num)="data">
        <div class="ellipsis">{{data.item.acc_num}}</div>
      </template>
      <template #cell(assigned_to)="data">
        <div class="ellipsis">{{data.item.assigned_to}}</div>
      </template>
      <template #cell(group)="data">
        <div class="ellipsis">{{data.item.group}}</div>
      </template>
      <template #cell(institution)="data">
        <div class="ellipsis">{{data.item.institution}}</div>
      </template>
      <template #cell(patient_email)="data">
        <div class="ellipsis">{{data.item.patient_email}}</div>
      </template>
      <template #cell(patient_id)="data">
        <div class="ellipsis">{{data.item.patient_id}}</div>
      </template>
      <template #cell(patient_name)="data">
        <div class="ellipsis">{{data.item.patient_name}}</div>
      </template>
      <template #cell(patient_telephone)="data">
        <div class="ellipsis">{{data.item.patient_telephone}}</div>
      </template>
      <template #cell(reading_physician)="data">
        <div class="ellipsis">{{data.item.reading_physician}}</div>
      </template>
      <template #cell(referring_physician)="data">
        <div class="ellipsis">{{data.item.referring_physician}}</div>
      </template>
      <template #cell(reported_by)="data">
        <div class="ellipsis">{{data.item.reported_by}}</div>
      </template>
      <template #cell(study_desc)="data">
        <div class="ellipsis">{{data.item.study_desc}}</div>
      </template>
      <template #cell(study_id)="data">
        <div class="ellipsis">{{data.item.study_id}}</div>
      </template>
      <template #cell(sex)="data">
        <div class="ellipsis">{{data.item.sex}}</div>
      </template>
    </b-table>
    <div class="bottomOfPage" ref="paginationControls">
      <b-navbar type="dark" variant="dark">
        <b-nav-form>
          <b-input-group prepend="Show:" size="sm">
            <b-form-select v-model="perPage" :options="perPageOptions" @change="handlePerPageChange" size="sm"></b-form-select>
          </b-input-group>
        </b-nav-form>
        <b-navbar-nav v-if="perPage > 0">
          <b-pagination class="ml-2" 
            v-model="currentPage" 
            :total-rows="filtered.length" 
            :per-page="perPage" 
            align="fill"
            size="sm"
            :limit="5"
            :first-number="true"
            :last-number="true">
          </b-pagination>
        </b-navbar-nav>
      </b-navbar>
    </div>
  </div>
</template>

<script>
import uuid from 'uuid'
import broadcast from '../common/broadcast.js'
import dicomWeb from '../common/dicomweb.js'
import permissions from '../common/permissions.js'
import webServices from '../common/webServices.js'
import workflow from '../common/workflow.js'
import worklistFields from '../common/worklistFields.json'
import worklistAltViewers from '../common/worklistAltViewers.json'
import AddToStudy from './AddToStudy.vue'
import AssignStudy from './AssignStudy.vue'
import PatientInfoEditor from './PatientInfoEditor.vue'
import RouteStudy from './RouteStudy.vue'

// Handle simple table filters, ref:
// https://stackoverflow.com/questions/52959195/bootstrap-vue-b-table-with-filter-in-header
//
export default {
  name: 'worklistTable',
  components: {
    AddToStudy,
    AssignStudy,
    PatientInfoEditor,
    RouteStudy
  },
  data() {
    return {
      buttonSize: "sm",
      currentPage: this.$store.state.worklistCurrentPage,
      modalities: ['CR', 'CT', 'DR', 'DX', 'MG', 'MR', 'NM', 'OT', 'PT', 'RF', 'RG', 'SC', 'SR', 'US', 'VL', 'XA', 'XC'],
      perPageOptions: [
          { value: 2, text: '2' },
          { value: 5, text: '5' },
          { value: 10, text: '10' },
          { value: 15, text: '15' },
          { value: 25, text: '25' },
          { value: 50, text: '50' },
          { value: 100, text: '100' },
          { value: 0, text: 'All' }
      ],
      perPage: this.$store.state.worklistPerPage,
      tableHeight: "800px",
      filters: {
      },
      checkedStudies: [],
      sortBy: this.$store.state.worklistSortBy,
      sortDesc: this.$store.state.worklistSortDesc
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.$log.debug("Querying server for its modality list.")
    webServices.readSystemSetting("modalities")
    .then(response => {
      if ((response != null) && Array.isArray(response) && (response.length > 0)) {
        this.$log.debug("Adding system modalities")
        for (var i=0; i<response.length; i++)
        {
          if (!this.modalities.includes(response[i])) {
            this.modalities.push(response[i])
          }
        }
        this.modalities.sort()
      }
    })
    .catch(err => {
      this.$log.error("Error fetching cached settings: "+err)
    })
    this.handleResize()
    try {
      document.activeElement.blur()
    }
    catch(e) {
      this.$log.warn("unable to remove focus: "+e.message)
    }
  },
  computed: {
    locale() {
      return this.$store.state.locale
    },
    groups() {
      var groupsForUser = []
      try {
        let groupsFromToken = this.$store.state.keycloak.tokenParsed['groups']
        for (var i=0; i<groupsFromToken.length; i++) {
          groupsForUser.push(groupsFromToken[i].substring(1))
        }
      }
      catch {
        groupsForUser = []
      }
      return groupsForUser
    },
    showCheckboxes() {
      var groupsWithAssignStudies = []
      try {
        let groupsFromToken = this.$store.state.keycloak.tokenParsed['groups']
        for (var i=0; i<groupsFromToken.length; i++) {
          if (permissions.hasPermission(groupsFromToken[i], permissions.CAN_ASSIGN_STUDIES)) {
            groupsWithAssignStudies.push(groupsFromToken[i].substring(1))
          }
        }
      }
      catch {
        groupsWithAssignStudies = []
      }
      return (groupsWithAssignStudies.length > 0)
    },
    fields() {
      // Initialize with fields that will always be displayed.
      //
      var fieldDefns = []
      if (this.showCheckboxes) {
        fieldDefns = [
          { 
            "key": "checkbox",
            "label": "✔️",
            "sortable": false,
            "size": 0,
            "thClass": "bg-secondary text-white position-sticky",
            "width": 30
          }
        ]
      }

      // Add fields in order based on user settings.
      //
      let columnsToDisplay = this.$store.state.worklistColumns;
      for (var i=0; i < columnsToDisplay.length; i++) {
        for (var j=0; j < worklistFields.data.length; j++) {
          let defn = worklistFields.data[j]
          if (defn.key == columnsToDisplay[i]) {
            fieldDefns.push(defn)
          }
        }
      }
      return fieldDefns
    },
    filtered() {
      const filtered = this.worklist.filter(item => {
        return Object.keys(this.filters).every(key => {
          let colVal = item[key]
          let flags = 'i'
          switch(key) {
            case 'diagnostic':
              colVal = this.diagnostic(item)
              flags = ''
              break
            case 'dob':
              colVal = (colVal==null) ? '---' : new Date(colVal).toLocaleDateString(this.locale)
              break
            case 'rcvd_date_time':
            case 'report_date_time':
            case 'study_date_time':
              colVal = (colVal==null) ? '---' : new Date(colVal).toLocaleString(this.locale)
              break
            default:
            break
          }
          return String(colVal).match(new RegExp(this.filters[key], flags))
        })
      })
      return (filtered.length > 0) ? filtered : []
    },
    viewerLiteEnabled() {
      return this.$store.state.worklistActions.includes('viewer_lite')
    },
    viewerFullEnabled() {
      return this.$store.state.worklistActions.includes('viewer_full')
    },
    exportStudyEnabled() {
      return this.$store.state.worklistActions.includes('export_study')
    },
    addObjectsEnabled() {
      return this.$store.state.worklistActions.includes('add_objects')
    },
    assignStudyEnabled() {
      return this.$store.state.worklistActions.includes('assign_study')
    },
    markReadEnabled() {
      return this.$store.state.worklistActions.includes('mark_read')
    },
    markStatEnabled() {
      return this.$store.state.worklistActions.includes('mark_stat')
    },
    patientHistoryEnabled() {
      return this.$store.state.worklistActions.includes('patient_history')
    },
    editPatientEnabled() {
      return this.$store.state.worklistActions.includes('edit_patient')
    },
    routeStudyEnabled() {
      return this.$store.state.worklistActions.includes('route_study')
    },
    unlockStudyEnabled() {
      return this.$store.state.worklistActions.includes('unlock_study')
    },
    viewReportEnabled() {
      return this.$store.state.worklistActions.includes('view_report')
    },
    horosEnabled() {
      return this.isAltViewerEnabled('horos')
    },
    osirixEnabled() {
      return this.isAltViewerEnabled('osirix')
    },
    radiantEnabled() {
      return this.isAltViewerEnabled('radiant')
    },
    slicerEnabled() {
      return this.isAltViewerEnabled('3dslicer')
    },
    storeCheckedStudies() {
      return this.$store.state.checkedStudies
    },
    userId() {
      return this.$store.state.keycloak.tokenParsed.sub
    },
    weasisEnabled () {
      return this.$store.state.worklistAltViewers.includes('weasis')
    },
    worklist() {
      return this.$store.state.worklist
    },
    worklistColumnWidths() {
      return this.$store.state.worklistColumnWidths
    },
    worklistPerPage() {
      return this.$store.state.worklistPerPage
    }
  },
  watch: {
    filtered(newVal/*, oldVal*/) {
      var newCheckedStudies = []
      for (var i=0; i<newVal.length; i++) {
        for (var j=0; j<this.checkedStudies.length; j++) {
          if (this.checkedStudies[j] == (newVal[i].group+'|'+newVal[i].study_uid)) {
            newCheckedStudies.push(this.checkedStudies[j])
            break;
          }
        }
      }
      if (newCheckedStudies.length != this.checkedStudies.length) {
        this.$store.commit('changeCheckedStudies', newCheckedStudies)
      }
      this.$store.commit('changeWorklistCount', newVal.length);
    },
    worklistPerPage(newVal/*, oldVal*/) {
      this.perPage = newVal
    },
    storeCheckedStudies(newVal/*, oldVal*/) {
      this.checkedStudies = newVal
    }
  },
  methods: {
    actionAddToStudy(studyUid) {
      if (studyUid != '') {
        this.$refs.addToStudy.show(studyUid)
      }
      return true
    },
    actionAssignStudy(studyUid) {
      if (studyUid != '') {
        this.$refs.assignStudy.show(studyUid)
      }
      return true
    },
    actionDownloadStudy(studyUid) {
      if (studyUid != '') {
        const entry = this.$store.getters.worklistEntryForStudy(studyUid)
        const toastMsg = "Download started [" + webServices.getTitleForEntry(entry) + "]..."
        this.displayToast(toastMsg, 'info')
        dicomWeb.downloadStudy(entry)
      }
      return true
    },
    actionMarkRead(studyUid) {
      if (studyUid != '') {
        const entry = this.$store.getters.worklistEntryForStudy(studyUid)
        const state = (entry.read) ? 'unread' : 'read'
        webServices.updateStudyRead(entry, state)
        .then(response => {
          this.$log.debug(response)
          if (response.result == 'OK') {
            entry.read = response.read
            entry._rowVariant = webServices.reportStatusToVariant(entry.report_status, entry.read, entry.stat)
            let toastMsg = "[" + webServices.getTitleForEntry(entry) + "] marked "
            toastMsg += (entry.read) ? " READ" : " UNREAD"
            this.displayToast(toastMsg, 'success')
          }
          else {
            const toastMsg = response.result + " [" + webServices.getTitleForEntry(entry) + "]"
            this.displayToast(toastMsg, 'warning')
          }
        })
        .catch(err => {
          this.$log.error("Error updating read setting for study: "+err)
        })
      }
      return true
    },
    actionMarkStat(studyUid) {
      if (studyUid != '') {
        const entry = this.$store.getters.worklistEntryForStudy(studyUid)
        const state = (entry.stat) ? 'normal' : 'stat'
        webServices.updateStudyStat(entry, state)
        .then(response => {
          this.$log.debug(response)
          if (response.result == 'OK') {
            entry.stat = response.stat
            entry._rowVariant = webServices.reportStatusToVariant(entry.report_status, entry.read, entry.stat)
            let toastMsg = "[" + webServices.getTitleForEntry(entry) + "] marked "
            toastMsg += (entry.stat) ? " EMERGENCY PRIORITY" : " NORMAL PRIORITY"
            this.displayToast(toastMsg, 'success')
          }
          else {
            const toastMsg = response.result + " [" + webServices.getTitleForEntry(entry) + "]"
            this.displayToast(toastMsg, 'warning')
          }
        })
        .catch(err => {
          this.$log.error("Error updating stat setting for study: "+err)
        })
      }
      return true
    },
    actionEditPatientInfo(studyUid) {
      if (studyUid != '') {
        this.$store.commit('changeSelectedStudyUid', studyUid)
        this.$refs.patientInfoEditor.show()
      }
      return true
    },
    actionPatientHistory(studyUid) {
      var answer = true
      if ((studyUid != '') && (this.$store.state.activeComponent == 'ReportEditor')) {
        answer = window.confirm('Report open in editor may have unsaved changes. Are you sure you want to open another report?')
      }
      if (answer) {
        this.$store.commit('changeActiveComponent', 'PatientHistory')
        this.$store.commit('changeActiveStudyUid', '')
        this.$store.commit('changeActiveStudyUid', studyUid)
      }
      return true
    },
    actionReportDisabled(studyUid) {
      var disabled = false
      if (this.$store.getters.openReportsInWindow) {
        let worklistReportWindow = this.$store.state.reportWindows[this.$store.state.uid]
        disabled = ((worklistReportWindow !== undefined) && !worklistReportWindow.closed && (this.$store.state._selectedStudyUid == studyUid))
      }
      else {
        disabled = (((this.$store.state.activeComponent == 'ReportEditor') || (this.$store.state.activeComponent == 'ReportViewer')) && (this.$store.state.activeStudyUid == studyUid))
      }
      return disabled
    },
    actionReport(studyUid) {
      var answer = true
      if ((studyUid != '') && this.$store.getters.isReportOpenForEdit(this.$store.state.uid)) {
        answer = window.confirm('Report open in editor may have unsaved changes. Are you sure you want to open another report?')
      }
      if (answer) {
        if (this.$store.getters.openReportsInWindow) {
          if ((this.$store.state.activeComponent == 'ReportEditor') || (this.$store.state.activeComponent == 'ReportViewer')) {
            this.$store.commit('changeActiveComponent', '')
            this.$store.commit('changeActiveStudyUid', '')
          }
          let reportWindowUid = this.$store.state.uid
          let payload = {
            'studyUid': studyUid,
            'windowUid': reportWindowUid
          }
          this.$store.commit('changeSelectedStudyUids', payload)
          if ((this.$store.state.reportWindows[reportWindowUid] === undefined) || (this.$store.state.reportWindows[reportWindowUid].closed)) {
            var box = this.$store.state.reportWindowBox
            const windowOpts = 'popup=1,left='+box.x+',top='+box.y+',height='+box.h+',width='+box.w
            let reportWindowName = 'saincepacs_report_'+reportWindowUid
            let reportWindow = window.open('/#/report?uid='+encodeURIComponent(reportWindowUid), reportWindowName, windowOpts)
            this.$store.commit('changeReportWindows', {
              'window': reportWindow, 
              'windowUid': reportWindowUid
            })
          }
          else {
            workflow.openStudy(studyUid, workflow.TARGET_REPORT_WINDOW, reportWindowUid)
            .then(() => {
              this.$store.state.reportWindows[reportWindowUid].focus()
              broadcast.postSelectedStudy(workflow.TARGET_REPORT_WINDOW, reportWindowUid)
            })
            .catch(err => {
              this.displayToast(err.message, 'warning')
            })
          }
        }
        else {
          workflow.openStudy(studyUid, workflow.TARGET_REPORT_SIDEPANEL, this.$store.state.uid)
          .then(() => {
            this.$store.commit('changeActiveComponent', 'ReportViewer')
            this.$store.commit('changeActiveStudyUid', '')
            this.$store.commit('changeActiveStudyUid', studyUid)
          })
          .catch(err => {
            this.displayToast(err.message, 'warning')
          })
        }
      }
      return true
    },
    actionRouteStudy(studyUid) {
      if (studyUid != '') {
        this.$refs.routeStudy.show(studyUid)
      }
      return true
    },
    actionCheckedAll(checked) {
      this.$log.debug(`checked=${checked}`)
      this.checkedStudies = []
      if (checked) {
        for(var i = 0; i < this.filtered.length; i++) {
          let item = this.filtered[i]
          this.checkedStudies.push(item.group+'|'+item.study_uid)
        }
      }
      this.$store.commit('changeCheckedStudies', this.checkedStudies)
    },
    actionCheckedStudy(/*checked*/) {
      this.$store.commit('changeCheckedStudies', this.checkedStudies)
    },
    actionUnlockStudy(studyUid) {
      // Force lock release for study.
      //
      var worklistEntry = this.$store.getters.worklistEntryForStudy(studyUid)
      if (worklistEntry != null) {
        webServices.updateStudyLock(worklistEntry, 'unlock_force')
        .then(response => {
          if (response.result == 'OK') {
            worklistEntry.lock = ''
            let mgmtReportEntry = this.$store.getters.mgmtReportEntryForStudy(studyUid, false)
            if (mgmtReportEntry != null) {
              mgmtReportEntry._l = ''
            }
            delete this.$store.state.openStudies[studyUid]
          }
          else {
            const errorMsg = "[" + webServices.getTitleForEntry(worklistEntry) + "] " + response.result
            this.displayToast(errorMsg, 'warning')
          }
        })   
        .catch(err => {
          this.$log.error("Error updating lock setting for study: "+err.message)
          const errorMsg = "[" + webServices.getTitleForEntry(worklistEntry) + "] Unable to unlock study."
          this.displayToast(errorMsg, 'warning')
        })
      }
    },
    actionViewer(studyUid, useFull) {
      let viewerRoute = (useFull) ? 'viewer' : 'viewer_lite'
      this.updateWorklistSorted()
      
      if (this.$store.getters.openViewersInWindow) {
        // +TODO+ This will always create a new windowUid - add code to reuse existing windowUid if needed here.
        // Code below will already handle whether to create a new window or use existing window assoc w windowUid.
        //
        let viewerWindowUid = uuid.v4()
        viewerRoute += '?uid=' + encodeURIComponent(viewerWindowUid)
        let closeViewerRoute = (useFull) ? 'viewer_lite' : 'viewer'
        let payload = {
          'studyUid': studyUid,
          'windowUid': viewerWindowUid
        }
        this.$store.commit('changeSelectedStudyUids', payload)
        this.$log.debug("viewerWindowRoute="+this.$store.state.viewerWindowsRoute[viewerWindowUid])
        if ((this.$store.state.viewerWindowsRoute[viewerWindowUid] !== undefined) &&
            this.$store.state.viewerWindowsRoute[viewerWindowUid].startsWith(closeViewerRoute+'?') &&
            (this.$store.state.viewerWindows[viewerWindowUid] !== undefined)) {
          this.$log.debug('Closing viewer window for other route')
          this.$store.state.viewerWindows[viewerWindowUid].close()
          this.$store.commit('changeViewerWindows', {
            'window': null,
            'windowUid': viewerWindowUid
          })
        }
        this.$store.commit('changeViewerWindowsRoute', {
          'route': viewerRoute,
          'windowUid': viewerWindowUid
        })
        if ((this.$store.state.viewerWindows[viewerWindowUid] === undefined) || this.$store.state.viewerWindows[viewerWindowUid].closed) {
          var box = this.$store.state.viewerWindowBox
          const windowOpts = 'popup=1,left='+box.x+',top='+box.y+',height='+box.h+',width='+box.w
          let viewerWindowName = 'saincepacs_viewer_'+viewerWindowUid
          let viewerWindow = window.open(`/#/${viewerRoute}`, viewerWindowName, windowOpts)
          this.$store.commit('changeViewerWindows', {
            'window': viewerWindow,
            'windowUid': viewerWindowUid
          })
        }
        else {
          workflow.openStudy(studyUid, workflow.TARGET_VIEWER, viewerWindowUid)
          .then(() => {
            this.$store.state.viewerWindows[viewerWindowUid].focus()
            broadcast.postSelectedStudy(workflow.TARGET_VIEWER, viewerWindowUid)
          })
          .catch(err => {
            this.displayToast(err.message, 'warning')
          })
        }
      }
      else {
        workflow.openStudy(studyUid, workflow.TARGET_VIEWER, this.$store.state.uid)
        .then(() => {
          this.$store.commit('changeSelectedStudyUid', studyUid)
          this.$store.state.worklistCurrentPage = this.currentPage
          this.$store.state.worklistPerPage = this.perPage
          this.$store.state.worklistSortBy = this.sortBy
          this.$store.state.worklistSortDesc = this.sortDesc
          this.$store.commit('changePrevRoute', 'worklist')
          this.$router.replace(viewerRoute)
        })
        .catch(err => {
          this.displayToast(err.message, 'warning')
        })
      }
      return true
    },
    actionOsiriX(studyUid) {
      // Uses DICOM Q/R. Need to configure AETs on OsiriX and PACS server.
      // https://www.osirix-viewer.com/resources/ris-integration/
      //
      if (studyUid != '') {
        var worklistEntry = this.$store.getters.worklistEntryForStudy(studyUid)
        if (worklistEntry != null) {
          const serverName = encodeURIComponent(worklistEntry['group'])
          var osirixUrl = 'osirix://?methodName=retrieve&serverName='+serverName+'&then=open&retrieveOnlyIfNeeded=yes' +
            '&filterKey=StudyInstanceUID&filterValue=' + encodeURIComponent(studyUid)
          this.handleAltViewerLaunch(osirixUrl)

          webServices.updateStudyUserHistoryNoPromise(worklistEntry, 'images')
        }
        else {
          this.$log.error('Unable to find worklist entry for studyUID='+studyUid)
        }
      }
      return true
    },
    actionRadiAnt(studyUid){
      // Uses DICOM Q/R. Need to configure AETs on RadiAnt and PACS server.
      // https://www.radiantviewer.com/dicom-viewer-manual/pacs-integration.html
      //
      if (studyUid != '') {
        var worklistEntry = this.$store.getters.worklistEntryForStudy(studyUid)
        if (worklistEntry != null) {
          const aet = this.$store.getters.aetForGroup('/'+worklistEntry['group'])
          var radiantUrl = 'radiant://?n=paet&v='+encodeURIComponent('"'+aet+'"')+'&n=pstv&v=0020000D&v='+encodeURIComponent('"'+studyUid+'"')
          this.handleAltViewerLaunch(radiantUrl)

          webServices.updateStudyUserHistoryNoPromise(worklistEntry, 'images')
        }
      }
      return true
    },
    actionSlicer(studyUid){
      // Uses DICOMweb with token, no setup needed within 3D Slicer.
      // https://discourse.slicer.org/t/new-dicomweb-features-launch-slicer-from-web-browser-and-download-upload-data-sets-to-the-cloud-using-dicomweb/17811
      //
      if (studyUid != '') {
        var worklistEntry = this.$store.getters.worklistEntryForStudy(studyUid)
        if (worklistEntry != null) {
          const aet = this.$store.getters.aetForGroup('/'+worklistEntry['group'])
          webServices.createDicomwebToken(aet)
          .then(response => {
            const token = response
            this.$log.debug('dicomweb token='+token)
            const qidoUrl = this.$store.state.dicomWebQidoUrl.replace(/\/DCM4CHEE/, '/'+token)
            const dicomWebEndpoint = encodeURIComponent(qidoUrl)
            const accessToken = Date.now()
            var slicerUrl = 'slicer://viewer/?studyUID=' + studyUid + '&access_token=' + accessToken +
              '&dicomweb_endpoint=' + dicomWebEndpoint + '&dicomweb_uri_endpoint=' + dicomWebEndpoint
            this.handleAltViewerLaunch(slicerUrl)

            webServices.updateStudyUserHistoryNoPromise(worklistEntry, 'images')
          })
          .catch(err => {
            this.$log.error('dicomweb token request error: '+err.message)
          })
        }
        else {
          this.$log.error('Unable to find worklist entry for studyUID='+studyUid)
        }
      }
      return true
    },
    actionWeasis(studyUid){
      // Uses DICOMweb with token, no setup needed within Weasis.
      // https://nroduit.github.io/en/basics/customize/integration/
      //
      if (studyUid != '') {
        var worklistEntry = this.$store.getters.worklistEntryForStudy(studyUid)
        if (worklistEntry != null) {
          const aet = this.$store.getters.aetForGroup('/'+worklistEntry['group'])
          webServices.createDicomwebToken(aet)
          .then(response => {
            const token = response
            this.$log.debug('dicomweb token='+token)
            const qidoUrl = this.$store.state.dicomWebQidoUrl.replace(/\/DCM4CHEE/, '/'+token)
            var weasisUrl = 'weasis://'
            weasisUrl += encodeURIComponent('$dicom:rs --url "'+qidoUrl+'" -r "studyUID='+studyUid+'" --query-ext "&includedefaults=false"')
            this.handleAltViewerLaunch(weasisUrl)

            webServices.updateStudyUserHistoryNoPromise(worklistEntry, 'images')
          })
          .catch(err => {
            this.$log.error('dicomweb token request error: '+err.message)
          })
        }
        else {
          this.$log.error('Unable to find worklist entry for studyUID='+studyUid)
        }
      }
      return true
    },
    clearFilters() { 
      const keys = Object.keys(this.filters)
      for(var k = 0; k < keys.length; k++) {
        this.filters[keys[k]] = ''
      }
    },
    diagnostic(item) {
      var diagnostic = (item._rowVariant == 'light') ? 'In Transit' : 'Unread'
      switch(item.report_status) {
          case 'DRAFT':
          case 'AMENDED DRAFT':
              if (item.read) {
                  diagnostic = 'Read'
              }
              else {
                  diagnostic = 'Unverified'
              }
              break;
          case 'AMENDED PRELIMINARY':
          case 'PRELIMINARY':
              diagnostic = 'Read'
              break;
          case 'AMENDED FINAL':
          case 'FINAL':
              diagnostic = 'Final'
              break;
          default:
              if (item.read) {
                  diagnostic = 'Read'
              }
              break;
      }
      return diagnostic
    },
    canMarkRead(item) {
      return (item.report_status == '---') && permissions.hasPermission(item.group, permissions.CAN_EDIT_REPORTS)
    },
    displayToast(message, variant) {
          this.$bvToast.toast(message, {
              autoHideDelay: 5000,
              solid: true,
              title: 'INSPIRE PACS',
              variant: variant,
          })
    },
    handleAltViewerLaunch(viewerUrl) {
        this.$log.debug('Launching alternative viewer: ' + viewerUrl)
        this.$refs.altViewerLauncher.href = viewerUrl;
        this.$refs.altViewerLauncher.click()
    },
    handlePerPageChange(/*event*/) {
      this.$store.commit('changeWorklistPerPage', this.perPage)
      webServices.updateUserSetting("worklistPerPage", this.perPage)
      .then(response => {
        this.$log.debug(response.data)
      })
      .catch(err => {
        this.$log.error("Error updating cached setting for worklistPerPage: "+err)
      })
    },
    handleResize(/*event*/) {
      let wh = (window.outerHeight > window.innerHeight) ? window.innerHeight : window.outerHeight
      this.tableHeight = "" + (wh - this.$refs.paginationControls.clientHeight - this.$refs.topDiv.getBoundingClientRect().top - 20) + "px"
    },
    handleSortChanged(/*ctx*/) {
      this.updateWorklistSorted()
    },
    isAltViewerEnabled(scheme) {
      var enabled = false
      for (var i=0; i < worklistAltViewers.data.length; i++) {
        if (worklistAltViewers.data[i].key == scheme) {
          enabled = worklistAltViewers.data[i].enabled & this.$store.state.worklistAltViewers.includes(scheme)
          break
        }
      }
      return enabled
    },
    lockStudy(item) {
      let lock = false
      if (permissions.hasPermission(item.group, permissions.CAN_LOCK_STUDY)) {
        lock = ((item.lock != '') && (item.lock != this.userId))
      }
      return lock
    },
    rowClicked(/*item*/) {
      // Ignore for now. Possible future action.
      //
      return true
    },
    rowDblClicked(item) {
      if (item)
      {
        const useLiteViewer = (this.$store.state.worklistViewers.dbl_click_route == 'viewer_lite')
        this.$log.debug("useLiteViewer="+useLiteViewer)
        if (useLiteViewer) {
          this.actionViewer(item.study_uid, false)
        }
        else {
          this.actionViewer(item.study_uid, true)
        }
      }
      return true
    },
    rowRank(status) {
      var statusRank = 0
      switch(status) {
        case '': // locked
          statusRank = 5
          break
        case 'success': // final
          statusRank = 4
          break
        case 'primary': // preliminary|read
          statusRank = 3
          break
        case 'secondary': // draft
          statusRank = 2
          break
        case 'warning': // unread
          statusRank = 1
          break;
        case 'light': // in transit
        default:
          statusRank = 0
          break
      }
      return statusRank
    },
    sortCompare(aRow, bRow, key /*, sortDesc, formatter, compareOptions, compareLocale*/) {
      if ((key == 'study_date_time') || (key == 'report_status') || (key == 'report_date_time') || (key == 'diagnostic')) {
        const aL = ((aRow['lock'] != '') && (aRow['lock'] != this.userId)) ? 1 : 0
        const bL = ((bRow['lock'] != '') && (bRow['lock'] != this.userId)) ? 1 : 0
        if (aL == bL) {
          const aR = this.rowRank(aRow['_rowVariant'])
          const bR = this.rowRank(bRow['_rowVariant'])
          if (aR == bR) {
            const aS = aRow['stat'] ? 1 : 0
            const bS = bRow['stat'] ? 1 : 0
            if (aS == bS) {
              const aD = aRow['study_date_dcm']
              const bD = bRow['study_date_dcm']
              if (aD == bD) {
                const aT = aRow['study_time_dcm']
                const bT = bRow['study_time_dcm']
                return bT - aT
              }
              return aD - bD
            }
            return aS - bS
          }
          return bR - aR
        }
        return bL - aL
      }
      else if (key == 'dob') {
        const aD = aRow['dob_dcm']
        const bD = bRow['dob_dcm']
        return bD - aD
      }
      else {
        // Fallback to default sort routine.
        //
        return false;
      }
    },
    updateWorklistSorted() {
      this.$store.commit('changeWorklistSorted', this.$refs.wlTable.sortedItems)
    }
  }
}
</script>
<style scoped>
.wlTable {
  background-color: #ffdab9 !important;
}
.md-18 {
  font-size: 18px;
}
.table { 
  text-align: left;
}
.bottomOfPage {
  position: absolute;
  bottom: 0px;
  left: 0px;
}
.stack-on-text {
  position: absolute;
  padding: 1px;
  left: 0;
  z-index: 0;
  text-align: left;
  align-items: left;
  font-size: 10px;
}
.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.noellipsis {
  overflow: hidden;
  white-space: wrap;
}
.viewerlabel {
  margin-left: -2px;
  font-size: 16px;
}
</style>