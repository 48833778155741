<template>
  <div>
    <b-modal ref="warningDialog" title="Updates Not Saved"
      header-bg-variant="warning" header-text-variant="dark"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      content-class="shadow" @ok="handleWarningOK" ok-only>
      <span v-html="warningMsg"></span>
    </b-modal>
    <b-modal ref="modalRef" title="Assign Study" size="lg" scrollable
      header-bg-variant="secondary" header-text-variant="light"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      content-class="shadow"
      ok-title="Save" @ok="handleSave()" :ok-disabled="checkedUsers.length==0">
      <b-badge class="d-none d-sm-block overflow-hidden" variant="info" show size="sm">
        <b-icon v-if="entry.stat" scale="1.0" icon="patch-exclamation-fill" class="text-danger mr-1"></b-icon>
        <b-icon v-if="entry.read" scale="1.0" icon="check-circle-fill"></b-icon>
        &nbsp;
        {{ title }}
      </b-badge>
      <b-badge class="d-none d-sm-block overflow-hidden" variant="secondary" show size="sm">{{ title2 }}</b-badge>
      <b-table outlined class="small"
          responsive="true"
          :sticky-header="tableHeight"
          :items="filteredUserOptions"
          :fields="fields"
          primary-key="text">
        <template slot="top-row">
          <td colspan="2">
            <b-form-input ref="userFilterInput" v-model="userFilter" placeholder="Filter for user..." autofocus @update="templateId = ''"></b-form-input>
          </td>
        </template>
        <template #cell(actions)="data">
          <div class="float-right"><b-checkbox v-model="checkedUsers" :value="data.item.value" :disabled="data.item.value==''"/></div>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import webServices from '../common/webServices'

export default {
  name: 'assignStudy',
  components: {
  },
  data() {
    return {
      checkedUsers: [],
      fields: [
        { "key": "actions", "label": "", "sortable": false, "thClass": "bg-secondary text-white" },
        { "key": "text", "label": "User(s)", "sortable": true, "thClass": "bg-secondary text-white" },
      ],
      studyUid: '',
      userFilter: '',
      userOptions: [],
      warningMsg: ''
    }
  },
  computed: {
    filteredUserOptions() {
      var filteredUserOpts = this.userOptions.filter(item => {
        return item['text'].match(new RegExp(this.userFilter, "i"))
      })
      if (filteredUserOpts.length == 0) {
        filteredUserOpts.push({ value: '', text: 'No users match', username: '', _rowVariant: 'warning' })
      }
      return filteredUserOpts
    },
    tableHeight() {
      return "" + (window.innerHeight - 150) + "px"
    },
    entry() {
      const entry = this.$store.getters.worklistEntryForStudy(this.studyUid)
      if (entry != null) {
        return entry
      }
      else {
        return webServices.getEmptyWorklistEntry()
      }
    },
    title() {
      return webServices.getTitleForEntry(this.entry)
    },
    title2() {
      return ""+this.checkedUsers.length+" selected users"
    }
  },
  methods: {
    show(studyUid) {
      this.studyUid = studyUid
      this.checkedUsers = []
      this.userFilter = ''
      this.userOptions = []
      this.$refs['modalRef'].show()
      webServices.readGroupUsers('/'+this.entry.group)
      .then(response => {
        const responseUserIds = Object.keys(response)
        if (responseUserIds.length > 0) {
          this.userOptions = []
          for (var i=0; i < responseUserIds.length; i++) {
            const userId = responseUserIds[i]
            this.userOptions.push({
              value: userId,
              text: response[userId].full_name,
              username: response[userId].username,
              _rowVariant: 'primary'
            })
          }
        }
      })
      .catch(err => {
        this.$log.error("Error getting group users: "+err.message)
      })
    },
    hide() {
      this.$refs['modalRef'].hide()
    },
    handleSave() {
      // Validate entries
      //
      var userEntries = []
      for(var i=0; i < this.userOptions.length; i++) {
        const userOption = this.userOptions[i]
        for (var j=0; j < this.checkedUsers.length; j++) {
          if (userOption.value == this.checkedUsers[j]) {
            userEntries.push({
              userId: userOption.value,
              userFullName: userOption.text,
              username: userOption.username
            })
          }
        }
      }
      webServices.updateStudyAssignment(this.entry.group, this.entry.study_uid, userEntries)
      .then(response => {
        this.$log.debug(response.data)
        webServices.readWorklist()
      })
      .catch(err => {
        this.$log.error("Error assigning study: "+err)
        this.warningMsg = 'Error assigning study, changes not saved.'
        this.$refs.warningDialog.show()
      })
      .finally(() => {
        this.hide()
      })
    },
    handleWarningOK() {
      this.warningMsg = ''
    }
  }
};
</script>
<style scoped>
</style>