import { render, staticRenderFns } from "./RouteStudy.vue?vue&type=template&id=59657fa6&scoped=true&"
import script from "./RouteStudy.vue?vue&type=script&lang=js&"
export * from "./RouteStudy.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59657fa6",
  null
  
)

export default component.exports