<template>
  <div>
    <b-modal ref="closeSidePanelDialog" title="Unable to Open Patient Record"
      header-bg-variant="warning" header-text-variant="dark"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      content-class="shadow" ok-only>
      <span v-html="warningMsg"></span>
    </b-modal>
    <b-modal ref="warningDialog" title="Updates Not Saved"
      header-bg-variant="warning" header-text-variant="dark"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      content-class="shadow" @ok="handleWarningOK" ok-only>
      <span v-html="warningMsg"></span>
    </b-modal>
    <b-modal ref="modalRef" title="Patient Information Editor"
      size="xl" scrollable
      header-bg-variant="secondary" header-text-variant="light"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      content-class="shadow"
      ok-title="Save" @ok="handleSave()">
      <b-form>
        <b-row>
          <b-col cols="2">
            <b-form-group label="Prefix:" label-for="patient_name_prefix">
              <b-form-input id="patient_name_prefix" v-model="updatedEntry.patient_name_prefix" placeholder=""/>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="First Name:" label-for="patient_name_first">
              <b-form-input id="patient_name_first" v-model="updatedEntry.patient_name_first" placeholder=""/>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label="Middle:" label-for="patient_name_middle">
              <b-form-input id="patient_name_middle" v-model="updatedEntry.patient_name_middle" placeholder=""/>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Last Name:" label-for="patient_name_last">
              <b-form-input id="patient_name_last" v-model="updatedEntry.patient_name_last" placeholder=""/>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label="Suffix:" label-for="patient_name_suffix">
              <b-form-input id="patient_name_suffix" v-model="updatedEntry.patient_name_suffix" placeholder=""/>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="MRN (Patient ID):" label-for="patient_id">
              <b-form-input id="patient_id" v-model="updatedEntry.patient_id" placeholder="Enter patient ID" required/>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Date of Birth:" label-for="dob">
              <b-form-datepicker id="dob" v-model="updatedEntry.dob_datepicker"/>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Sex:" label-for="sex">
              <b-form-radio-group id="sex" v-model="updatedEntry.sex">
                <b-form-radio value="F">Female</b-form-radio>
                <b-form-radio value="M">Male</b-form-radio>
                <b-form-radio value="U">Unknown/Other</b-form-radio>
                <b-form-radio value="">Not Specified</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Address:" label-for="patient_address">
              <b-form-input id="patient_address" v-model="updatedEntry.patient_address" placeholder="Enter patient address"/>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group label="Telephone:" label-for="patient_telephone">
              <b-form-input id="patient_telephone" v-model="updatedEntry.patient_telephone" placeholder="Enter patient telephone"/>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Email:" label-for="patient_email">
              <b-form-input id="patient_email" v-model="updatedEntry.patient_email" type="email" placeholder="Enter patient email address"/>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import webServices from '../common/webServices'

export default {
  name: 'patientInfoEditor',
  components: {
  },
  data() {
    return {
      updatedEntry: {
        patient_id_orig: '',
        patient_name_dcm: '',
        patient_name: '',
        patient_name_last: '',
        patient_name_first: '',
        patient_name_middle: '',
        patient_name_prefix: '',
        patient_name_suffix: '',
        patient_age: '',
        dob_dcm: '',
        dob: '',
        dob_datepicker: '',
        sex: '',
        patient_id: '',
        patient_address: '',
        patient_email: '',
        patient_telephone: ''
      },
      warningMsg: ''
    }
  },
  computed: {
    entry() {
      const entry = this.$store.getters.worklistEntryForStudy(this.studyUid)
      if (entry != null) {
        return entry
      }
      else {
        return webServices.getEmptyWorklistEntry()
      }
    },
    studyUid() {
      return this.$store.getters.selectedStudyUid(this.$store.state.uid)
    }
  },
  methods: {
    show(retry = false) {
      if (!retry) {
        this.updatedEntry = Object.assign({}, this.entry)
        this.updatedEntry.patient_id_orig = this.entry.patient_id
        this.updatedEntry.patient_name_first = ''
        this.updatedEntry.patient_name_middle = ''
        this.updatedEntry.patient_name_last = ''
        this.updatedEntry.patient_name_prefix = ''
        this.updatedEntry.patient_name_suffix = ''
        try {
          var parsedArray = this.updatedEntry.patient_name_dcm.split('^')
          if (parsedArray.length > 0) {
            this.updatedEntry.patient_name_last = parsedArray[0]
          }
          if (parsedArray.length > 1) {
            this.updatedEntry.patient_name_first = parsedArray[1]
          }
          if (parsedArray.length > 2) {
            this.updatedEntry.patient_name_middle = parsedArray[2]
          }
          if (parsedArray.length > 3) {
            this.updatedEntry.patient_name_prefix = parsedArray[3]
          }
          if (parsedArray.length > 4) {
            this.updatedEntry.patient_name_suffix = parsedArray[4]
          }
        }
        catch(err) {
          this.$log.debug("error parsing DICOM patient name: " + err.message)
        }
        if (this.entry.dob_dcm.length == 8) {
          const dob = this.entry.dob_dcm
          this.updatedEntry.dob_datepicker = dob.substring(0,4)+'-'+dob.substring(4,6)+'-'+dob.substring(6)
        }
        else {
          this.updatedEntry.dob_datepicker = ''
        }
      }
      this.$refs['modalRef'].show()
    },
    hide() {
      this.$refs['modalRef'].hide()
    },
    handleSave() {
      // Validate entries
      //
      this.hide()
      this.updatedEntry.patient_name_dcm = this.updatedEntry.patient_name_last + '^' + 
        this.updatedEntry.patient_name_first + '^' +
        this.updatedEntry.patient_name_middle + '^' +
        this.updatedEntry.patient_name_prefix + '^' +
        this.updatedEntry.patient_name_suffix
      var parsedArray = this.updatedEntry.patient_name_dcm.split('^')
      if (parsedArray.length > 5) {
        this.warningMsg += "<li>The '^' character is not allowed in patient name."
      }
      if (this.updatedEntry.patient_name_dcm.length > 64) { // PN
        this.warningMsg += '<li>Patient name parts must be 60 characters or less in total.'
      }
      parsedArray = this.updatedEntry.patient_id.split('^')
      if (parsedArray.length > 1) {
        this.warningMsg += "<li>The '^' character is not allowed in MRN (Patient ID)."
      }
      if (this.updatedEntry.patient_id.length > 64) { // LO
        this.warningMsg += '<li>Patient ID must be 64 characters or less.'
      }
      if (this.updatedEntry.dob_datepicker.length == 0) {
        this.updatedEntry.dob_dcm = ''
        this.updatedEntry.patient_age = ''
      }
      else if (this.updatedEntry.dob_datepicker.length == 10) { // DA
        const dob = this.updatedEntry.dob_datepicker
        this.updatedEntry.dob = dob.substring(5,7) + '/' + dob.substring(8) + '/' + dob.substring(0,4)
        this.updatedEntry.dob_dcm = dob.substring(0,4) + dob.substring(5,7) + dob.substring(8)
        this.updatedEntry.patient_age = ''
        if ((parseInt(this.entry.study_date_dcm, 10) > 0) && (parseInt(this.updatedEntry.dob_dcm, 10) > 0))
        {
          try {
            let sdYear = parseInt(this.entry.study_date_dcm.substring(0,4), 10)
            let sdMonth = parseInt(this.entry.study_date_dcm.substring(4,6), 10)
            let sdDay = parseInt(this.entry.study_date_dcm.substring(6,8), 10)
            let dobYear = parseInt(this.updatedEntry.dob_dcm.substring(0,4), 10)
            let dobMonth = parseInt(this.updatedEntry.dob_dcm.substring(4,6), 10)
            let dobDay = parseInt(this.updatedEntry.dob_dcm.substring(6,8), 10)
            var age = sdYear - dobYear;
            if (sdMonth < dobMonth) {
              age -= 1
            }
            else if (sdMonth == dobMonth) {
              if (sdDay < dobDay) {
                age -= 1
              }
            }
            this.updatedEntry.patient_age = '' + age + 'Y'
          }
          catch(err)
          {
            this.$log.error("error parsing dates for patient age: "+err.message)
            this.updatedEntry.patient_age = ''
          }
        }
      }
      else {
        this.warningMsg += '<li>Date of birth is invalid.'
      }
      if (this.updatedEntry.patient_address.length > 64) { // LO
        this.warningMsg += '<li>Address must be 64 characters or less.'
      }
      if (this.updatedEntry.patient_telephone.length > 16) { // SH
        this.warningMsg += '<li>Address must be 16 characters or less.'
      }
      if (this.warningMsg !== '') {
        this.warningMsg = 'Fix the following errors before saving:<ul>' + this.warningMsg + '</ul>'
        this.$refs.warningDialog.show()
      }
      else {
        webServices.updatePatientInfo(this.entry, this.updatedEntry)
        .then(response => {
          this.$log.debug(response.data)
          webServices.readWorklist()
        })
        .catch(err => {
          this.$log.error("Error updating patient demographics: "+err)
          this.warningMsg = 'Error updating patient demographics, changes not saved.'
          this.$refs.warningDialog.show()
        })
        .finally(() => {
          this.hide()
        })
      }
      this.$log.debug("PatientInfoEditor handleSave() exiting")
    },
    handleWarningOK() {
      this.warningMsg = ''
      this.show(true)
    }
  }
};
</script>
<style scoped>
</style>